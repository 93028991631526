.Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px 10px 8px 20px;
    border-radius: 1px;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.08), 0px 25px 30px -15px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
    border-left: 4px solid #0a6eb4;
    color: #031c2d;
}